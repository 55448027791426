import * as React from "react"

const KindredLogo = ({ ...props }) => {
  return (
    <svg
      width={30}
      height={30}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.088 8.61c-.67.352-1.181.972-1.558 1.643-.25.444-.415.92-.603 1.395-.23.581-1.073 1.71-1.115 1.718 1.99.457 3.161.442 4.446-.454 1.23-.858 1.511-2.323 2.099-3.664.186-.424.437-.816.74-1.155.07-.08.268-.33.408-.387-2.27-.056-3.651.5-4.417.904Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.84 14.49C29.84 7.069 24.274.93 17.115.05v2.474c5.807.861 10.28 5.899 10.28 11.967 0 3.478-1.472 6.617-3.82 8.826l-.06-.06-9.473-9.516V0C6.663.666.858 6.909.858 14.49c0 8.025 6.5 14.554 14.49 14.554 1.7 0 3.33-.297 4.845-.84l-1.946-1.971a11.95 11.95 0 0 1-2.898.356c-6.642 0-12.046-5.427-12.046-12.098 0-5.12 3.184-9.504 7.667-11.267v19.108h3.073V17.78l7.17 7.273 1.771 1.794c.827-.517 1.6-1.114 2.306-1.782l.015-.014h-.001a14.54 14.54 0 0 0 4.535-10.56Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default KindredLogo

