import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "./styles.scss";
import { get, orderBy } from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function ArchivesComponent(props) {
  const [isMounted, setIsMounted] = useState(false);
  const [year, setYear] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [archiveList, setArchiveList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handledClicked = (actionDescription, year, actionType) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url:
        window.location.href
          .replace("https://", "")
          .replace("http://", "")
          .split("20")[0] + year,
      ActionType: actionType,
      NewsletterID:  "00000000-0000-0000-0000-000000000000",
      NewsletterDate: itemDate,
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: actionDescription,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const linkClicked = (actionDescription, url, mainId,itemName="") => {
    let articleTitle = ""
    let email = localStorage.getItem("email");
    let itemDate = url.split("/")[2];
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    actionDescription = getThemeTitle(actionDescription);
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
    ) {
      articleTitle = actionDescription
      actionDescription = "Theme Title - " + actionDescription;
    }
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "clicked",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      ArticleTitle : articleTitle?articleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const RadioInput = ({ label, value, checked, type, setter }) => {
    return (
      <>
        <input
          className="custom__radio__group--button"
          id={label}
          type="radio"
          name="year"
          onChange={() => setter(value)}
          checked={checked === value}
        />
        <label className="custom__radio__group--label" htmlFor={label}>
          {label}
        </label>
      </>
    );
  };

  useEffect(() => {
    const { pathname } = location;
    if (!isMounted) {
      const fetchData = async () => {
        let { value } = await props.getLTWeeklyArchiveList({
          year: pathname.split("/")[3],
        });
        setYear(pathname.split("/")[3]);
        setArchiveList(get(value, "results", ""));
        setIsLoader(false);
      };
      fetchData();
      setIsMounted(true);
      let yr =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      handledClicked("Archive Page", yr, "pageview");
    }
  }, [archiveList, isMounted, location, props]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const onChangeYear = async (year) => {
    handledClicked(`Showing LT weekly ${year}`, year, "clicked");
    setYear(year);
    setIsLoader(true);
    let { value } = await props.getLTWeeklyArchiveList({ year: year });
    setArchiveList(get(value, "results", ""));
    setIsLoader(false);
    navigate(`/LTWeekly/archives/${year}`, { state: {} });
  };

  const getThemeTitle = (title) => {
    if (title.includes("<span")) {
      title = title.split("<span>")[1];
      title = title.split("</span>")[0];
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>"))
        {
          let first = title?.split("<i>")[0]
          let second = title?.split("<i>")[1]
          title = first + " " + second
          let firstI = title?.split("</i>")[0]
          let secondI = title?.split("</i>")[1]
          title = firstI + " " + secondI
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          // console.log(title,'title')
          if (title.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title.includes("<i>"))
          {
            let first = title?.split("<i>")[0]
            let second = title?.split("<i>")[1]
            title = first + " " + second
            let firstI = title?.split("</i>")[0]
            let secondI = title?.split("</i>")[1]
            title = firstI + " " + secondI
          }
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>"))
      {
        let first = title?.split("<i>")[0]
        let second = title?.split("<i>")[1]
        title = first + " " + second
        let firstI = title?.split("</i>")[0]
        let secondI = title?.split("</i>")[1]
        title = firstI + " " + secondI
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>"))
      {
        let first = title?.split("<i>")[0]
        let second = title?.split("<i>")[1]
        title = first + " " + second
        let firstI = title?.split("</i>")[0]
        let secondI = title?.split("</i>")[1]
        title = firstI + " " + secondI
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const yearsObject = [
    { key: "2024", value: "2024" },
    { key: "2023", value: "2023" },
    { key: "2022", value: "2022" },
    { key: "2021", value: "2021" },
  ];

  return (
    <>
      <section className="section__archives">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="archives__container">
                <div className="sarchives__year">
                  <div className="custom__radio__group">
                    {yearsObject.map((data, i) => {
                      return (
                        <div key={i} className="custom__radio__group">
                          <RadioInput
                            value={data.key}
                            setter={onChangeYear}
                            checked={year}
                            label={data.value}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="archives__list">
                  <>
                    {isLoader ? (
                      <>
                        <h1 style={{ marginTop: 200 }}> </h1>
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: "#000" }}
                              spin
                            />
                          }
                          className="loader__full"
                        />
                      </>
                    ) : (
                      archiveList.map((item, i) => {
                        item.ChildList = orderBy(
                          item.ChildList,
                          (items) => parseInt(items.ItemName),
                          ["asc"]
                        );
                        return (
                          <div className="archives__list--item" key={i}>
                            <h2 className="archives__list--title">
                              {moment(item.ItemName, "MM DD YYYY").format(
                                "MMMM DD, YYYY"
                              )}
                            </h2>
                            <ul className="archives__topics">
                              <li
                                key={get(item, "ItemId", "") + 0}
                                className="archives__topic--item"
                              >
                                <Link
                                  className="archives__topic--link"
                                  onClick={() => {
                                    linkClicked(
                                      "Intro",
                                      `/LTWeekly/${moment(
                                        get(item, "ItemName", "")
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`,
                                      get(item, "ItemId", "")
                                    );
                                    navigate(
                                      `/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`,
                                      { state: { data: item, from: "feed" } }
                                    );
                                    window.location.reload(true);
                                  }}
                                  to={`#`}
                                >
                                  Intro
                                </Link>
                                <ol className="archives__topics--child">
                                  {get(item, "ChildList", []).length > 0 &&
                                    get(item, "ChildList", []).map((it, j) => {
                                      return (
                                        <li
                                          key={j}
                                          className="archives__topic--child-item"
                                        >
                                          <Link
                                            className="archives__topic--child-link"
                                            onClick={() =>
                                              linkClicked(
                                                get(it, "ShortDescription", ""),
                                                `/LTWeekly/${moment(
                                                  get(item, "ItemName", "")
                                                ).format("MMMM-DD-YYYY")}/${get(
                                                  it,
                                                  "ItemId",
                                                  ""
                                                )}`,
                                                get(item, "ItemId", "")
                                              )
                                            }
                                            to={`/LTWeekly/${moment(
                                              item.ItemName,
                                              "MM DD YYYY"
                                            ).format("MMMM-DD-YYYY")}/${
                                              it.ItemId
                                            }`}
                                            state={{
                                              data: item,
                                              isFrom: "feed",
                                            }}
                                          >
                                            {getThemeTitle(
                                              get(it, "ShortDescription", "")
                                            )}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ol>
                              </li>
                              <li
                                key={get(item, "ItemId", "") + 1}
                                className="archives__topic--item"
                              >
                                <Link
                                  onClick={() =>
                                    linkClicked(
                                      "Stock Market Check",
                                      `/LTWeekly/${moment(
                                        get(item, "ItemName", "")
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}/stock-check`,
                                      get(item, "ItemId", "")
                                    )
                                  }
                                  to={`/LTWeekly/${moment(
                                    get(item, "ItemName", ""),
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    item,
                                    "ItemId",
                                    ""
                                  )}/stock-check`}
                                  state={{ data: item, isFrom: "feed" }}
                                  className="archives__topic--link"
                                >
                                  Stock Market Check
                                </Link>
                              </li>
                              {get(item, "OtherNews", []).length > 0 && (
                                <li
                                  key={get(item, "ItemId", "") + 2}
                                  className="archives__topic--item"
                                >
                                  <Link
                                    onClick={() =>
                                      linkClicked(
                                        "Key News by Sub-Sector",
                                        `/LTWeekly/${moment(
                                          get(item, "ItemName", "")
                                        ).format("MMMM-DD-YYYY")}/${get(
                                          item,
                                          "ItemId",
                                          ""
                                        )}/sub-sector`,
                                        get(item, "ItemId", "")
                                      )
                                    }
                                    to={`/LTWeekly/${moment(
                                      get(item, "ItemName", ""),
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      item,
                                      "ItemId",
                                      ""
                                    )}/sub-sector`}
                                    state={{ data: item, isFrom: "feed" }}
                                    className="archives__topic--link"
                                  >
                                    Key News by Sub-Sector
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        );
                      })
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
