import * as React from "react"

const LeftArrow = ({ ...props }) => {
  return (
    <svg
      width={19}
      height={16}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.292 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.415-1.414L2.414 8 8.07 2.343A1 1 0 0 0 6.656.93L.292 7.293ZM18.02 7H1v2h17.02V7Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default LeftArrow

