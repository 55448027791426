import { connect } from 'react-redux'
import { unsubscribeUser } from '../../store/home/duck'
import UnsubscribeUser from './component'

const UnsubscribeUserContainer = connect(
  // Map state to props
  (/*state*/) => ({
    
  }),
  // Map actions to dispatch and props
  {
    unsubscribeUser
  }
)(UnsubscribeUser)

export default UnsubscribeUserContainer
