import { connect } from 'react-redux'
import { getLTWeeklyArchiveList, SavedLTWebsiteAnalytics } from '../../store/home/duck'
import ArchivesComponent from './component'

const ArchivesContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    getLTWeeklyArchiveList
  }
)(ArchivesComponent)

export default ArchivesContainer
