import { connect } from 'react-redux'
import { getLtweeklyData, SavedLTWebsiteAnalytics, updateCurrentPosition, initHomePhase } from '../../store/home/duck'
import FeedComponent from './component'

const FeedContainer = connect(
  // Map state to props
  state => ({
    ltweeklyData: state.home.ltweeklyData,
    totalLtweeklyData: state.home.totalLtweeklyData,
    ltweeklyPhase: state.home.ltweeklyPhase,
    currentPosition: state.home.currentPosition,
    totalPageNumber: state.home.totalPageNumber,
    ltweeklyTotalRecords: state.home.ltweeklyTotalRecords
  }),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    updateCurrentPosition,
    getLtweeklyData,
    initHomePhase
  }
)(FeedComponent)

export default FeedContainer
