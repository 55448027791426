import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics } from '../../../store/home/duck'
import Header from './component'

const HeaderContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics
  }
)(Header)

export default HeaderContainer
