import React from "react";
import LeslieMallon from "../../../images/leslie-mallon.png";

import "./styles.scss";

export default function AnnouncementComponent(props) {
  return (
    <>
      <div className="announcement">
        <div className="announcement__row">
          <div className="announcement__left">
            <div className="announcement__left--inner">
              <img src={LeslieMallon} alt="" />
            </div>
          </div>
          <div className="announcement__right">
            <div className="announcement__right--content">
              <div className="announcement__right--content-text">
                “Our <b>LT Weekly Update</b> is a comprehensive weekly analysis aimed at
                helping our key corporate and investor clients cut through the
                noise and stay in front of what we view as the most important
                themes and developments driving the digital economy.”
              </div>
              <div className="announcement__author"><span className="announcement__author--divider">-</span> Leslie Mallon, Head of LionTree Public Markets</div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
