import React, { useState, useEffect } from "react";
import { useParams,useNavigate  } from "react-router-dom";
import ReactModal from "react-modal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification,Modal } from "antd";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function UnsubscribeUserComponent(props){

    const [isMount, setIsMount] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const params = useParams();
    const Navigate = useNavigate()
    // const callApi = async() => {
    //   setIsLoading(true)
    //     const { value } = await props.unsubscribeUser(params.email);
    //     setIsUnsubscribed(true)
    // }
    const callApi = async () => {
      setIsLoading(true);
      try {
        const { value } = await props.unsubscribeUser(params.email);
        setIsUnsubscribed(true);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'There was an error unsubscribing. Please try again later.'
        });
      } finally {
        setIsLoading(false);
      }
    };
    useEffect(()=>{
        if(!isMount){
            setIsMount(true)
        }
    },[])


    function obfuscateEmail(email) {
        // Split the email address into username and domain
        var parts = email.split('@');
        var username = parts[0];
        var domain = parts[1];
    
        // Obfuscate the username
        var obfuscatedUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
    
        // Obfuscate the domain
        var domainParts = domain.split('.');
        var obfuscatedDomain = domainParts[0].charAt(0) + '*'.repeat(domainParts[0].length - 1) + '.' + domainParts[1].charAt(0) + '*'.repeat(domainParts[1].length - 1);
    
        // Construct the obfuscated email
        var obfuscatedEmail = obfuscatedUsername + '@' + obfuscatedDomain;
    
        // Create and return the HTML element
        // var pElement = document.createElement('p');
        // pElement.className = 'text-sm email-obfuscate';
        // pElement.setAttribute('data-email', obfuscatedEmail);
        // pElement.textContent = obfuscatedEmail;
        // console.log(obfuscatedEmail,'pElement')
        return obfuscatedEmail;
    }
    
    return (
        <>
        <div className="successful-card">
          <div className="p-8 text-center">
           
         {isUnsubscribed && (
          <> <p className="email-obfuscate" data-email="v*****@r*****t.com">{obfuscateEmail(params.email).replace(".c**",".com")}</p>

<h1 className="heading-main">Opt Out Successful!</h1>
<h4 className="heading-submain">You'll no longer receive the following type of email communication from this sender.</h4>
</>
)}   
{!isUnsubscribed && ( <div className="unsunscribe-section">
           <h1 className="unsubscribe-heading">
           Unsubscribe from LionTree Weekly Top Themes
            </h1>
            <div className="unsubcribe-container">
           <p className="unsubscrbe-title">If you no longer wish to receive the LionTree Weekly Top Themes report, click the button below.<br/>
           <span style={{"display": "block","height": "10px"}}></span>
           If you wish to resubscribe, you will have to request access again by going to <a href="https://www.liontree.com/ltweekly?inboundpath=themeweb">liontree.com/ltweekly</a></p>
           <div>
            <button className="btn unsubscribe-btn btn__purple"
            onClick={()=>callApi()}
            >{isLoading ? <Spin indicator={antIcon} /> : "Unsubscribe"}</button>
           </div>
           </div>
           </div>
           )}
            {/* <div class="border-card">
              <table className="mx-auto w-100">
                  <tr>
                  <th className="text-start font-bold graytext border-card-td pb-2">EMAIL TYPE</th>
                  <th className="text-end font-bold graytext border-card-td pb-2">OPT OUT</th>
                  </tr>
                  <tr>
                  <td className="text-start pt-5">IIMWeeklyUnsubscribeGroup</td>
                  <td className="text-start pt-5 fw-bold red-color">Yes</td>
                  </tr>
              </table>
              <div className="btnwrap">
                <button className="viewbtn">View Opt Out Preferences</button>
              </div>
            </div> */}
          </div>
        </div>  
    
        </>
    )
      
}