import * as React from "react"

const Pdf = ({ ...props }) => {
  return (
    <svg
      width={22}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.446 0 .003 7.423v3.132h1.58V8.51H8.75V3.207H7.168v3.722H2.525l4.643-5.345H20.23v8.971h1.583V0H6.446Z"
        fill="#4D1B6C"
      />
      <path
        d="M1.723 13.18H3.36c.688 0 1.429.34 1.398 1.19-.026.766-.487 1.228-1.398 1.228H1.723V13.18ZM0 11.755v8.23h1.723v-2.988h1.91c1.548 0 2.638-1.095 2.638-2.576 0-1.55-1.172-2.665-2.639-2.665H0ZM9.207 13.18h1.274c1.672 0 2.29 1.086 2.295 2.603.002 1.548-.688 2.8-2.144 2.8H9.206V13.18Zm-1.675-1.425v8.23h3.446c2.36 0 3.594-2.077 3.557-4.202-.04-2.315-1.628-4.028-3.817-4.028H7.532ZM15.957 11.755v8.23h1.723v-3.47h3.618v-1.452H17.68v-1.871h4.14v-1.437h-5.864Z"
        fill="#4D1B6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.23 18.403v9.504l-3.54-.005-1.454 1.582 6.578.005V18.403H20.23ZM.003 21.195v8.276l6.579.004-1.457-1.582-3.542-.002v-6.696H.003Z"
        fill="#4D1B6C"
      />
      <path
        d="M10.125 22.064v6.904l-2.806-2.806-1.12 1.117 4.716 4.72 4.715-4.72-1.118-1.117-2.806 2.803v-6.9h-1.581Z"
        fill="#4D1B6C"
      />
    </svg>
  )
}
export default Pdf

