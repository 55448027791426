import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics, initPhase, getResources, initHomePhase } from '../../../store/home/duck'
import FooterComponent from './component'

const FooterContainer = connect(
  // Map state to props
  state => ({
    resourceData: state.home.resourceData,
    resourcePhase: state.home.resourcePhase,
   
  }),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    getResources,
    initHomePhase,
    initPhase
  }
)(FooterComponent)

export default FooterContainer
