import { Record } from 'immutable'
import { assign } from 'lodash'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'

// import { fromPromise } from 'rxjs/observable/fromPromise'
import { from } from 'rxjs';

import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable';

import * as api from './api';

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
// export const LTWEEKLY_DATA = 'home/LTWEEKLY_DATA'
export const LTWEEKLY_DATA_BY_DATE = 'home/LTWEEKLY_DATA_BY_DATE'
export const LTWEEKLY_ARCHIVES_DATA = 'home/LTWEEKLY_ARCHIVES_DATA'
export const LTWEEKLY_DATA_BY_ID = 'home/LTWEEKLY_DATA_BY_ID'

export const LTWEEKLY_DATA = 'home/LTWEEKLY_DATA'
export const LTWEEKLY_DATA_SUCCESS = 'home/LTWEEKLY_DATA_SUCCESS'
export const LTWEEKLY_DATA_ERROR = 'home/LTWEEKLY_DATA_ERROR'

export const GET_RESOURCES = 'home/GET_RESOURCES'
export const GET_RESOURCES_SUCCESS = 'home/GET_RESOURCES_SUCCESS'
export const GET_RESOURCES_ERROR = 'home/GET_RESOURCES_ERROR'

export const UPDATE_CURRENT_POSITION = 'home/UPDATE_CURRENT_POSITION'
export const SAVED_LT_WEBSITE_ANALYTICS = 'home/SAVED_LT_WEBSITE_ANALYTICS'

export const VALIDATE_USER = 'home/VALIDATE_USER'
export const INIT_HOME_PHASE = 'home/INIT_HOME_PHASE'
export const INITPHASE = 'home/INITPHASE'
export const UNSUBSCRIBE_USER = "home/UNSUBSCRIBE_USER";

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
    // We need this here to tell InitialState that there is a token key,
    // but it will be reset below to what is in localStorage, unless a value
    // is passed in when the object is instanciated
    data: {},
    ltweeklyData: [],
    ltweeklyPhase: INIT,
    ltweeklyTotalRecords: 0,
    totalLtweeklyData: [],
    totalPageNumber: 1,
    currentPosition: 0,
    resourcePhase: INIT,
    resourceData: {}
  }
  
  class InitialState extends Record(InitialStateInterface) {
    constructor(desiredValues) {
      // When we construct InitialState, we automatically update it's default value
      // for token to be what is stored in localStorage
      const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
      super(assign({ token }, desiredValues))
    }
  }


// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
    switch (action.type) {
      case LTWEEKLY_DATA: {
        const { payload } = action
        let number = payload.pageNumber
        return state
          .set('ltweeklyPhase', LOADING)
          .set('totalPageNumber', number)
      }
  
      case LTWEEKLY_DATA_SUCCESS: {
        const { payload } = action
          const totalData = state.totalLtweeklyData.concat(payload.results);
          return state
            .set('ltweeklyPhase', SUCCESS)
            .set('ltweeklyData', payload.results)
            .set('totalLtweeklyData', totalData)
            .set('ltweeklyTotalRecords', payload.totalRecords)
        }
  
      case LTWEEKLY_DATA_ERROR: {
        return state
          .set('ltweeklyPhase', ERROR)
      }

      case GET_RESOURCES: {
        return state
          .set('resourcePhase', LOADING)
      }
  
      case GET_RESOURCES_SUCCESS: {
        const { payload } = action
        
          return state
            .set('resourcePhase', "success")
            .set('resourceData', payload.ChildList)
        }
  
      case GET_RESOURCES_ERROR: {
        return state
          .set('resourcePhase', ERROR)
      }

      case LTWEEKLY_DATA_BY_DATE: {
        return state
        .set('ltweeklyPhase', INIT)
      }

      case INITPHASE: {
        return state
        .set('resourcePhase', INIT)
      }

      case UPDATE_CURRENT_POSITION: {
        const { payload } = action
        return state
        .set('currentPosition', payload.currentPosition)
      }
  
      default: {
        return state
      }

    }
    
}


/***********************************
 * Action Creators
 ***********/
export const getLtweeklyData = (value) => {
  return {
    type: LTWEEKLY_DATA,
    payload: value
  }
}

export const getResources = (value) => {
  return {
    type: GET_RESOURCES,
    payload: value
  }
}

export const getltWeeklyByDate = (value) => {
  return {
    type: LTWEEKLY_DATA_BY_DATE,
    payload: api.getltWeeklyByDate(value)
  }
}

export const getLtWeeklyDetailsById = (value) => {
  return {
    type: LTWEEKLY_DATA_BY_ID,
    payload: api.getLtWeeklyDetailsById(value)
  }
}

export const getLTWeeklyArchiveList = (value) => {
  return {
    type: LTWEEKLY_ARCHIVES_DATA,
    payload: api.GetLTWeeklyArchiveList(value)
  }
}


export const validateUser = (value) => {
  return {
    type: VALIDATE_USER,
    payload: api.validateUser(value)
  }
}

export const initHomePhase = (value) => {
  return {
    type: LTWEEKLY_DATA_BY_DATE
  }
}

export const initPhase = (value) => {
  return {
    type: INITPHASE
  }
}

export const updateCurrentPosition = (value) => {
  return {
    type: UPDATE_CURRENT_POSITION,
    payload: value
  }
}

export const SavedLTWebsiteAnalytics = (value) => {
  return {
    type: SAVED_LT_WEBSITE_ANALYTICS,
    payload: api.savedLTWebsiteAnalytics(value)
  }
}
export const unsubscribeUser = (value) => {
  return {
    type: UNSUBSCRIBE_USER,
    payload: api.unsubscribeUser(value),
  };
}
/***********************************
 * Epics
 ***********************************/
 const getLtweeklyDataEpic = action$ => 
 action$.pipe(
   ofType(LTWEEKLY_DATA),
   mergeMap(action => {
     return from(api.getLtweeklyData(action.payload)).pipe(
       flatMap(payload => [{
         type: LTWEEKLY_DATA_SUCCESS,
         payload
       }
     ]),
     catchError(error =>
         of({
           type: LTWEEKLY_DATA_ERROR,
           payload: { error }
         })
       )
     )
   })
 )

 const getResourcesDataEpic = action$ => 
 action$.pipe(
   ofType(GET_RESOURCES),
   mergeMap(action => {
     return from(api.GetResources(action.payload)).pipe(
       flatMap(payload => [{
         type: GET_RESOURCES_SUCCESS,
         payload
       }
     ]),
     catchError(error =>
         of({
           type: GET_RESOURCES_ERROR,
           payload: { error }
         })
       )
     )
   })
 )

 export const ltweeklyEpic = combineEpics(
  getLtweeklyDataEpic,
  getResourcesDataEpic
)
