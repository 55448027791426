import { fetch } from "../../utils";
import { get } from "lodash";
const HostName = process.env.REACT_APP_API_HOSTNAME;

export const getLtweeklyData = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklies?count=${get(
      value,
      "limit",
      10
    )}&page=${get(value, "pageNumber", 1)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const savedLTWebsiteAnalytics = (value) => {
  let header = {};
  if (
    localStorage.getItem("t") &&
    localStorage.getItem("t") != "?target=" &&
    localStorage.getItem("t") != "?target"
  ) {
    header = {
      "Content-Type": "application/json",
      EncryptionToken: localStorage.getItem("t")
        ? localStorage.getItem("t") != "?target="
          ? localStorage.getItem("t")
          : ""
        : "",
    };
  } else {
    header = {
      "Content-Type": "application/json",
    };
  }

  return fetch(`${HostName}/CustomItem/SavedLTWebsiteAnalytics`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getltWeeklyByDate = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyByDate?date=${get(
      value,
      "date",
      ""
    )}&direction=${get(value, "direction", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateUser = (value) => {
  return fetch(
    `${HostName}/CustomItem/ValidateUser?email=${get(value, "email", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLtWeeklyDetailsById = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyDetails?ItemId=${get(value, "id", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetLTWeeklyArchiveList = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyArchiveList?year=${get(
      value,
      "year",
      2022
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetResources = (value) => {
  return fetch(`${HostName}/CustomItem/GetResources`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const unsubscribeUser = (value) => {
  return fetch(`${HostName}/Dashboard/UnsubscribeUser?Email=${value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
