import * as React from "react"

const Filter = ({ ...props }) => {
  return (
    <svg
      width={21}
      height={13}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.911 2.178H1.09a1.089 1.089 0 0 1 0-2.178H19.91a1.089 1.089 0 0 1 0 2.178ZM16.9 7.233H4.095a1.089 1.089 0 0 1 0-2.178H16.9a1.089 1.089 0 0 1 0 2.178ZM14.296 12.286H6.698a1.089 1.089 0 0 1 0-2.178h7.598a1.089 1.089 0 1 1 0 2.178Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default Filter

