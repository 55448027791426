import * as React from "react"

const ScrollTop = ({ ...props }) => {
  return (
    <svg
      width={46}
      height={46}
      fill="#4D1B6C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 46C10.318 46 0 35.682 0 23S10.318 0 23 0s23 10.318 23 23-10.318 23-23 23Zm0-43.125C11.902 2.875 2.875 11.902 2.875 23c0 11.097 9.027 20.125 20.125 20.125 11.097 0 20.125-9.028 20.125-20.125 0-11.098-9.028-20.125-20.125-20.125Zm7.188 24.438c-.368 0-.737-.141-1.017-.422L23 20.72l-6.171 6.171a1.436 1.436 0 0 1-2.033 0 1.436 1.436 0 0 1 0-2.032l7.188-7.188a1.436 1.436 0 0 1 2.032 0l7.188 7.188a1.436 1.436 0 0 1-1.017 2.453ZM23 35.938a1.437 1.437 0 0 1-1.438-1.438V18.687a1.438 1.438 0 0 1 2.875 0V34.5c0 .795-.643 1.438-1.437 1.438Zm8.625-21.563h-17.25a1.438 1.438 0 0 1 0-2.875h17.25a1.437 1.437 0 1 1 0 2.875Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default ScrollTop

