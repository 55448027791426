import * as React from "react"

const Hamburger = ({ ...props }) => {
  return (
    <svg
      width={20}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.968 1.935h18.064a.968.968 0 0 0 0-1.935H.968a.968.968 0 0 0 0 1.935ZM19.032 6.452H.968a.968.968 0 0 0 0 1.935h18.064a.968.968 0 0 0 0-1.935ZM19.032 12.903H.968a.968.968 0 1 0 0 1.936h18.064a.968.968 0 1 0 0-1.936Z"
        fill="#000"
      />
    </svg>
  )
}
export default Hamburger

