import React, { useState, useEffect, useRef } from "react";
import { Drawer } from "antd";
import Header from "../../components/common/Header/container";
import Footer from "../../components/common/Footer/container";
import {
  Close,
  Filter,
  KindredLogo,
  LeftArrow,
  PdfSmall,
  RightArrow,
  ScrollTop,
} from "../../components/icons";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import LeslieMallon from "../../images/leslie-mallon.png";
import { sortBy, isEmpty, get, orderBy } from "lodash";
import { Interweave } from "interweave";
import "./styles.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const HOST_URL = process.env.REACT_APP_API_HOSTNAME;
const IMAGE_BLOB_URL = process.env.REACT_APP_IMAGE_BLOB_URL;
const REDIRECT_URL = "/iimform";

const WeeklyDetailComponent = (props) => {
  const [visible, setVisible] = useState(false);
  const [ltWeeklyId, setLtWeeklyId] = useState("");
  const [ltWeeklyItemId, setLtWeeklyItemId] = useState("");
  const [ltWeeklyItemData, setltWeeklyItemData] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [isNextDisable, setIsNextDisable] = useState(false);
  const [isAnalyticSave, setIsAnalyticSave] = useState(false);
  const [isPrevDisable, setIsPrevDisable] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [arr, setArray] = useState([]);
  const [nextLtWeeklyData, setNextLtWeeklyData] = useState({});
  const [prevLtWeeklyData, setPrevLtWeeklyData] = useState({});
  const [otherNews, setOtherNews] = useState([]);
  const [liTagValue, setliTagValues] = useState("");
  const intervalRef = useRef();
  const [isCookieConsent, setIsCookieConsent] = useState(false);
  const [themeLink, setThemeLink] = useState("");
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getThemeTitle = (title) => {
    if (title.includes("<span")) {
      title = title.split("<span>")[1];
      title = title.split("</span>")[0];
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>"))
        {
          let first = title?.split("<i>")[0]
          let second = title?.split("<i>")[1]
          title = first + " " + second
          let firstI = title?.split("</i>")[0]
          let secondI = title?.split("</i>")[1]
          title = firstI + " " + secondI
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          // console.log(title,'title')
          if (title.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title.includes("<i>"))
          {
            let first = title?.split("<i>")[0]
            let second = title?.split("<i>")[1]
            title = first + " " + second
            let firstI = title?.split("</i>")[0]
            let secondI = title?.split("</i>")[1]
            title = firstI + " " + secondI
          }
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>"))
      {
        let first = title?.split("<i>")[0]
        let second = title?.split("<i>")[1]
        title = first + " " + second
        let firstI = title?.split("</i>")[0]
        let secondI = title?.split("</i>")[1]
        title = firstI + " " + secondI
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>"))
      {
        let first = title?.split("<i>")[0]
        let second = title?.split("<i>")[1]
        title = first + " " + second
        let firstI = title?.split("</i>")[0]
        let secondI = title?.split("</i>")[1]
        title = firstI + " " + secondI
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };


  const scroll = () => {
    let splitUrl = window.location.pathname.split("/");
    let element = 0;
    if (splitUrl[4] === "stock-check" || splitUrl[4] === "sub-sector") {
      let id = splitUrl[4] && splitUrl[4].toLowerCase();
      setLtWeeklyItemId(id);
      element = document.getElementById(id);
    } else {
      let id = splitUrl[3] && splitUrl[3].toLowerCase();
      setLtWeeklyItemId(id);
      element = document.getElementById(id);
    }
    const yOffset = -80;
    const y =
      element &&
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const scrollOverLink = (id) => {
    setLtWeeklyItemId(id);
    const element = document.getElementById(id);
    const yOffset = -80;
    const y =
      element &&
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();

  const onTabChange = (value) => {
    if (value === "home") {
      navigate("/LTWeekly", { state: { name: "home" } });
      setActiveTab(value);
    } else if (value === "archives") {
      navigate("/LTWeekly/archives/2024", { state: { name: "archives" } });
      setActiveTab(value);
    } else if (value === "about") {
      navigate("/LTWeekly/about", { state: { name: "about" } });
      setActiveTab(value);
    }
  };

  const linkClicked = (actionDescription, url, mainId, ArticleTitle = "",itemName="") => {
    let email = localStorage.getItem("email");
    let itemDate = url.split("/")[2];
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    actionDescription = getThemeTitle(actionDescription);
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
    ) {
      ArticleTitle = actionDescription
      actionDescription = "Theme Title - " + actionDescription;
    }
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "clicked",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      ArticleTitle: ArticleTitle?ArticleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const pageViewLink = (actionDescription, url, mainId, itemDate,itemName = "") => {
    
    let email = localStorage.getItem("email");
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    actionDescription = getThemeTitle(actionDescription);
    let ArticleTitle = actionDescription;
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
    ) {
      actionDescription = "Theme Title - " + actionDescription;
    }
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "pageview",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: "LTWeekly Detail",
      ArticleTitle: ArticleTitle?ArticleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const handledClicked = (actionDescription, val, ItemId) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: ItemId,
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const handledLinkClicked = (
    actionDescription,
    val,
    ItemId,
    key,
    articleTitle = "",
    itemName = ""
  ) => {
    if (key === "IntroLink") {
      articleTitle = actionDescription
      actionDescription = "Theme Title - " + actionDescription;
    } else {
      actionDescription = "External Link - " + actionDescription;
    }
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    if (key === "OtherLink") {
      itemId = "";
    }
    if (key === "External") {
      itemId = "";
    }
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: ItemId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      ArticleTitle: articleTitle?articleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
    
      window.open(val, "_blank");
    
  };

  const handleLinkClick = (actionDescription, ItemId) => {
    let url = "";
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    if (actionDescription === "Next LT Weekly") {
      url =
        window.location.host +
        `/LTWeekly/${moment(
          get(nextLtWeeklyData, "ItemName", ""),
          "MM DD YYYY"
        ).format("MMMM-DD-YYYY")}/${get(nextLtWeeklyData, "ItemID", "")}`;
      ItemId = get(nextLtWeeklyData, "ItemID", "");
      itemDate = get(nextLtWeeklyData, "ItemName", "");
    } else if (actionDescription === "Previous LT Weekly") {
      url =
        window.location.host +
        `/LTWeekly/${moment(
          get(prevLtWeeklyData, "ItemName", ""),
          "MM DD YYYY"
        ).format("MMMM-DD-YYYY")}/${get(prevLtWeeklyData, "ItemID", "")}`;
      ItemId = get(prevLtWeeklyData, "ItemID", "");
      itemDate = get(prevLtWeeklyData, "ItemName", "");
    } else {
      url = window.location.href;
    }
    let email = localStorage.getItem("email");

    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: url.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: ItemId,
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const clicScrollTop = (id) => {
    let path = location.pathname;
    let new_path = "/LTWeekly/" + path.split("/")[2] + "/" + id;
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLtWeeklyItemId(id);
    navigate(new_path, { state: { data: ltWeeklyItemData } });
  };

  const otherNewData = async (data) => {
    const pathname = location.pathname;
    let splitUrl = pathname.split("/");
    let itemId = splitUrl[3] && splitUrl[3].toLowerCase();
    let otherNewsData = get(data, "OtherNews[0].ChildList", []);
    otherNewsData = sortBy(otherNewsData, [
      function (o) {
        return o.obj;
      },
    ]);
    let catlist = [];
    let promiseFilter = otherNewsData.map((catData, i) => {
      let catName = get(catData, "Category", "");
      let obj = catlist.findIndex((x) => x.catName === catName?.split("|")[0]);
      if (obj === -1) {
        if (catName !== "") {
          catlist.push({
            catName: catName?.split("|")[0],
            items: [catData],
          });
        }
      } else {
        let index = catlist.findIndex(
          (x) => x.catName === catName?.split("|")[0]
        );
        let catIndexData = catlist[index];
        let catItems = catIndexData?.items;
        catItems.push(catData);
        let items = orderBy(catItems, ["LongDescription"], ["asc"]);
        catIndexData.items = items;
        catlist[index] = catIndexData;
      }
      return "";
    });
    Promise.all(promiseFilter);
    catlist = sortBy(catlist, (e) => {
      return e.catName?.toLowerCase();
    });
    // catlist = orderBy(catlist, ["catName"], ["asc"]);
    setOtherNews(catlist);
  };
  const apiCall = async () => {
    let pathname = window.location.pathname;
    let date = new Date(pathname.split("/")[2]);
    date = date.setDate(date.getDate() - 1);
    let nextValue = await props.getltWeeklyByDate({
      date: `${moment(date).format("MM-DD-YYYY")}`,
      direction: "next",
    });
    if (nextValue) {
      setIsMounted(!isMounted);
      let data = get(nextValue, "value.results[0]", {});
      if (!isEmpty(data)) {
        setIsNextDisable(false);
        setNextLtWeeklyData(get(nextValue, "value.results[0]", {}));
        scroll();
      } else {
        setIsNextDisable(true);
      }
    } else {
      setIsNextDisable(true);
    }
    let prevValue = await props.getltWeeklyByDate({
      date: `${moment(date).format("MM-DD-YYYY")}`,
      direction: "prev",
    });
    if (prevValue) {
      setIsMounted(!isMounted);
      let data = get(prevValue, "value.results[0]", {});
      if (!isEmpty(data)) {
        setIsPrevDisable(false);
        setPrevLtWeeklyData(get(prevValue, "value.results[0]", {}));
        scroll();
      } else {
        setIsPrevDisable(true);
      }
    } else {
      setIsPrevDisable(true);
    }
  };

  useEffect(() => {
    if (location && location.pathname === "/LTWeekly") {
      setActiveTab("home");
    } else if (window.location.href.indexOf("/LTWeekly/archives") > -1) {
      setActiveTab("archives");
    } else if (location && location.pathname === "/LTWeekly/about") {
      setActiveTab("about");
    }

    let { state, search } = location;
    let userData = {};

    // Handle user's acceptance of cookies and remember it.
    let val = localStorage.getItem("userConsent");
    if (!isEmpty(search) && val === "accepted") {
      localStorage.setItem(
        "t",
        search.replace("?token=", "")?.replace("?t=", "")
      );
    }
    let data = get(state, "data", {});
    if (!isEmpty(data)) {
      data.ChildList = orderBy(
        data.ChildList,
        (item) => parseInt(item.ItemName),
        ["asc"]
      );
    }
    const pathname = location.pathname;
    let splitUrl = pathname.split("/");
    let itemName = splitUrl[2];
    let itemId = splitUrl[3] && splitUrl[3].toLowerCase();
    itemName = moment(itemName, "MM DD YYYY").format("MM-DD-YYYY");
    if (get(state, "isFrom", "") === "feed" && !isMounted) {
      otherNewData(get(state, "data", {}));
      let title = "";
      let id = "";
      let new_path = "";
      let themeIndex = ""
      if (splitUrl[4] === "stock-check" || splitUrl[4] === "sub-sector") {
        title =
          splitUrl[4] === "stock-check"
            ? "Stock Market Check"
            : "Key News by Sub-Sector";
        new_path =
          "/LTWeekly/" +
          location.pathname.split("/")[2] +
          "/" +
          get(data, "ItemID", "") +
          "/" +
          splitUrl[4];
      } else {
        let obj = get(data, "ChildList", []).find((x) => x.ItemID === itemId);
        if (obj !== undefined && obj !== null && !isEmpty(obj)) {
          title = obj.ShortDescription;
          themeIndex=obj.ItemName
          id = get(obj, "ItemID", "");
          new_path =
            "/LTWeekly/" + location.pathname.split("/")[2] + "/" + itemId;
        } else {
          title = "LTWeekly Detail";
          id = get(data, "ItemID", "");
          new_path =
            "/LTWeekly/" + location.pathname.split("/")[2] + "/" + itemId;
        }
      }
      if (!isAnalyticSave) {
        setIsAnalyticSave(true);
        pageViewLink(
          title,
          new_path,
          get(data, "ItemID", ""),
          get(data, "ItemName", ""),
          themeIndex
        );
      }
    }
    const fetchDataById = async (id, itemId, data) => {
      setltWeeklyItemData(data);
      let liTagValuedata = "";
      let childList = get(data, "ChildList", []).map((l) => {
        let new_path =
          "/LTWeekly/" +
          location.pathname.split("/")[2] +
          "/" +
          get(l, "ItemID", "") +
          "?";
        liTagValuedata += `<li><a href=${new_path}> ${getThemeTitle(
          get(l, "ShortDescription", "")
        )} </a> </li>`;

        return "";
      });
      await Promise.all(childList);
      setliTagValues(liTagValuedata);
      const arrlist = [];
      arrlist.push(data.ItemID);
      let promiseArray = get(data, "ChildList", []).map((item, i) => {
        arrlist.push(item.ItemID);
        return "";
      });
      await Promise.all(promiseArray);
      arrlist.push("stock-check");
      arrlist.push("sub-sector");
      setArray(arrlist);
      if (ltWeeklyItemId === "") {
        setLtWeeklyId(ltWeeklyItemId.ItemID);
        setLtWeeklyItemId(
          itemId ? itemId : get(ltWeeklyItemData, "ChildList[0].ItemID", "")
        );
      }
      let timerID = setInterval(() => {
        apiCall();
        scroll();
        setIsLoader(false);
        clearInterval(timerID);
      }, 500);
    };
    if(!get(data,"itemID","")){
      data = {}
    }
    if (isEmpty(data) && !isMounted) {
      const checkValidateUser = async (data) => {
        setIsLoader(true);
        setIsMounted(true);
        let email = get(location, "search", "").split("=")[1];
        email = email ? email.replaceAll("%22", "") : "";
        let userIsExists = localStorage.getItem("email");
        let isValidateUser = true;
        //if (!userIsExists && email) {
//           let getUserDetails = await props.validateUser({ email });
//           getUserDetails = get(getUserDetails, "value", {});
//           if (get(getUserDetails, "success", "")) {
//             // localStorage.setItem("email", get(getUserDetails, "message", ""));
//             isValidateUser = true;
//           } else {
//             // localStorage.setItem("email", "");
//             // isValidateUser = true;
//             // window.location.href = REDIRECT_URL;
//           }
//         } else {
//          // localStorage.setItem("email", email);
//           isValidateUser = true;
//         }
        if (isValidateUser || userIsExists) {
          let { value } = await props.getLtWeeklyDetailsById({ id: itemId });
          data = get(value, "results[0]", {});
          if (!isEmpty(data)) {
            data.ChildList = orderBy(
              data.ChildList,
              (item) => parseInt(item.ItemName),
              ["asc"]
            );
          }
          setltWeeklyItemData(data);
          otherNewData(data);
          let title = "";
          let id = "";
          let new_path = "";
          let themeIndex=""
          if (splitUrl[4] === "stock-check" || splitUrl[4] === "sub-sector") {
            title =
              splitUrl[4] === "stock-check"
                ? "Stock Market Check"
                : "Key News by Sub-Sector";
            new_path =
              "/LTWeekly/" +
              location.pathname.split("/")[2] +
              "/" +
              get(data, "ItemID", "") +
              "/" +
              splitUrl[4];
          } else {
            let obj = get(data, "ChildList", []).find(
              (x) => x.ItemID === itemId
            );
            if (obj !== undefined && obj !== null && !isEmpty(obj)) {
              title = obj.ShortDescription;
            themeIndex=obj.ItemName
              id = get(obj, "ItemID", "");
              new_path =
                "/LTWeekly/" + location.pathname.split("/")[2] + "/" + itemId;
            } else {
              title = "LTWeekly Detail";
              id = get(data, "ItemID", "");
              new_path =
                "/LTWeekly/" + location.pathname.split("/")[2] + "/" + itemId;
            }
          }
          if (!isAnalyticSave) {
            setIsAnalyticSave(true);
            pageViewLink(
              title,
              new_path,
              get(data, "ItemID", ""),
              get(data, "ItemName", ""),
              themeIndex
            );
          }
          if (isEmpty(data)) {
            navigate("/LTWeekly", { state: {} });
          }
          fetchDataById(itemName, data.ItemId, data);
        } else {
          window.location.href = REDIRECT_URL;
        }
      };
      checkValidateUser(data);
    } else if (!isMounted) {
      setIsMounted(true);
      otherNewData(data);
      fetchDataById(itemName, itemId, data);
    }
  }, [
    liTagValue,
    arr,
    isMounted,
    isNextDisable,
    otherNews,
    isPrevDisable,
    activeTab,
    ltWeeklyId,
    ltWeeklyItemId,
    ltWeeklyItemData,
  ]);

  const itemChange = (itemId) => {
    setLtWeeklyItemId(itemId);
    let path = location.pathname;
    let new_path = "/LTWeekly/" + path.split("/")[2] + "/" + itemId;
    if (itemId === "stock-check" || itemId === "sub-sector") {
      new_path =
        "/LTWeekly/" +
        path.split("/")[2] +
        "/" +
        get(ltWeeklyItemData, "ItemID", "") +
        "/" +
        itemId;
    }
    scroll();
    navigate(new_path, { state: { data: ltWeeklyItemData } });
  };

  const linkChange = (itemId) => {
    setLtWeeklyItemId(itemId);
    let path = location.pathname;
    let new_path = "/LTWeekly/" + path.split("/")[2] + "/" + itemId;
    scrollOverLink(itemId);
    navigate(new_path, { state: { data: ltWeeklyItemData, isFrom: "feed" } });
  };

  const mobileItemChange = (itemId) => {
    setLtWeeklyItemId(itemId);
    let path = location.pathname;
    let new_path = "/LTWeekly/" + path.split("/")[2] + "/" + itemId;
    if (itemId === "stock-check" || itemId === "sub-sector") {
      new_path =
        "/LTWeekly/" +
        path.split("/")[2] +
        "/" +
        itemId +
        "/" +
        get(ltWeeklyItemData, "ItemID", "");
    }
    scroll();
    navigate(new_path, { state: { data: ltWeeklyItemData } });
    setVisible(false);
  };

  const getLtWeekly = async (direction) => {
    setIsLoader(true);
    let date = "";
    if (direction === "next") {
      date = moment(get(ltWeeklyItemData, "Date", "")).format("MM-DD-YYYY");
    } else {
      date = moment(get(ltWeeklyItemData, "Date", "")).format("MM-DD-YYYY");
    }
    let { value } = await props.getltWeeklyByDate({
      date,
      direction,
    });
    setIsMounted(!isMounted);
    if (value) {
      setIsLoader(false);
      let data = get(value, "results[0]", {});
      if (data) {
        let new_path = `/LTWeekly/${moment(
          get(data, "ItemName", ""),
          "MM DD YYYY"
        ).format("MMMM-DD-YYYY")}/${get(data, "ItemID", "")}`;
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(async () => {
          if (!isEmpty(data)) {
            data.ChildList = orderBy(
              data.ChildList,
              (item) => parseInt(item.ItemName),
              ["asc"]
            );
          }
          setltWeeklyItemData(() => data);
          const arrlist = [];
          arrlist.push(data.ItemID);
          let promiseArray = get(data, "ChildList", []).map((item, i) => {
            arrlist.push(item.ItemID);
            return "";
          });
          await Promise.all(promiseArray);
          arrlist.push("stock-check");
          arrlist.push("sub-sector");
          setArray(arrlist);
        }, 100);
        otherNewData(data);
        let newPath =
          "/LTWeekly/" +
          `${moment(get(data, "ItemName", ""), "MM DD YYYY").format(
            "MMMM-DD-YYYY"
          )}` +
          "/" +
          `${get(data, "ItemID", "")}`;
        pageViewLink(
          "LTWeekly Detail",
          newPath,
          get(data, "ItemID", ""),
          get(data, "ItemName", "")
        );
        setLtWeeklyItemId(get(data, "ItemID", ""));
        let liTagValuedata = "";
        let childList = get(data, "ChildList", []).map((l) => {
          let new_path =
            "/LTWeekly/" +
            location.pathname.split("/")[2] +
            "/" +
            get(l, "ItemId", "") +
            "?";
          liTagValuedata += `<li><a href=${new_path}> ${getThemeTitle(
            get(l, "ShortDescription", "")
          )} </a> </li>`;
          return "";
        });
        await Promise.all(childList);
        setliTagValues(liTagValuedata);
        navigate(new_path, { state: { data: data, isFrom: "feed" } });
      }
    }
  };

  const introLinkTransformText = (node, children) => {
  
    if (node.tagName && node.tagName.toLowerCase() === "a") {
      let anchorLink = node.getAttribute("href");
      if(anchorLink.includes("?e=$email$")){
        return <></>
      }
      
       let themeIndex = ""
       let themeTitle=""
      if (anchorLink.includes("/LTWeekly")) {
        const themeObject = get(ltWeeklyItemData, "ChildList", []).find(
          (item) => {
            
            return item.ShortDescription.includes(anchorLink);
          }
        );
       
          themeIndex= themeObject ? get(themeObject, "ItemName", "").toString() : "";
          themeTitle= themeObject? getThemeTitle(get(themeObject,"ShortDescription","")):""
          
        let themeId = anchorLink.split("/")[anchorLink.split("/").length - 1];
        themeId = themeId.split("?")[0];
        themeId = themeId.toLowerCase();
        let path = location.pathname;
        let new_path =
          "https://" +
          window.location.host +
          "/LTWeekly/" +
          path.split("/")[2] +
          "/" +
          themeId;
          // console.log(children,'children')
          // if(children&&children[0]?.includes("Annual LionTree Sector Themes Survey")){
          //   return (
          //     <>
          //     <a
          //       onClick={() => {
          //         linkChange(themeId);
          //         handledLinkClicked(
          //           node.textContent,
          //           new_path,
          //           get(ltWeeklyItemData, "ItemId", ""),
          //           "IntroLink"
          //         );
          //       }}
          //       href="javascript:void(0);"
          //       rel="noreferrer"
          //     >
          //       {children}
          //     </a>
          //     &nbsp;
          //     <a 
          //     onClick={() => {
          //       // linkChange(themeId);
          //       handledLinkClicked(
          //         "CLICK HERE",
          //         "https://customervoice.microsoft.com/Pages/ResponsePage.aspx?id=DiTqAFurwkGho5p2tjdWkp4V1wSyFz5CtgFJDVP-rgdUQjNRR1VOM1ZTNk9XWkxLRzFPNlRLM1ZDNS4u&amp;vt=00ea240e-ab5b-41c2-a1a3-9a76b6375692_bd635ab3-154a-43d3-98a9-749a41bf0330_638399098160000000_NAM_Hash_orfbN1A6f2mMFtnpZaTm5%2fWBhg5nQcXmTtfyCPzJijQ%3d#msdynttrid=1MnUBxLZ8m_vjGZZsCgHALg58MPBbT9t3nlWkKjP8rI",
          //         get(ltWeeklyItemData, "ItemId", ""),
          //         "IntroLink"
          //       );
          //     }}
          //     href="javascript:void(0);"
          //     rel="noreferrer"
          //   >CLICK HERE</a>
          //     </>
          //   );
          // }else{
        return (
          <a
            onClick={() => {
              linkChange(themeId);
              handledLinkClicked(
                node.textContent,
                new_path,
                get(ltWeeklyItemData, "ItemID", ""),
                "IntroLink",
                themeTitle,
                themeIndex
              );
            }}
            href="javascript:void(0);"
            rel="noreferrer"
          >
            {children}
          </a>
        );
      // }
      } else {
        return (
          <a
            onClick={() => {
              handledLinkClicked(
                node.textContent,
                node.href,
                get(ltWeeklyItemData, "ItemID", ""),
                "External",
                themeTitle,
                themeIndex
              );
            }}
            href="javascript:void(0);"
            style={{ color: "#40a9ff", textDecoration: "underline" }}
            rel="noreferrer"
          >
            {children}
          </a>
        );
      }
    }
  };

  const transformText = (description, node, children,a) => {
    if (node.tagName.toLowerCase() === "img") {
      // node.removeAttribute("height");
      // node.removeAttribute("width");
      if (
        node.getAttribute("style") &&
        node.getAttribute("style")?.includes("100%")
      ) {
        node.removeAttribute("height");
        node.removeAttribute("width");
        node.setAttribute("style", "max-width: 60%; height: auto;");
      }
    }

  let anchorLink = node.getAttribute("href");


      let themeIndex = ""
       let themeTitle=""
       let descriptionNew=""
       if (node.tagName.toLowerCase() === "a") {
        if (anchorLink.includes("/LTWeekly")) {
        descriptionNew = node.textContent
        const descriptionUrl = extractUrlFromDescription(description);
    
        const themeObject = get(ltWeeklyItemData, "ChildList", []).find((item) => {
          const itemAnchorLink = extractUrlFromDescription(item.ShortDescription);
          return itemAnchorLink === descriptionUrl;
        });
    
        if (themeObject) {
          themeIndex = get(themeObject, "ItemName", "");
          themeTitle = getThemeTitle(themeObject.ShortDescription);
        }
    
      return (
        <a
          onClick={(event) =>{
            event.preventDefault()
            handledLinkClicked(
              descriptionNew,
              node.getAttribute("href"),
              get(ltWeeklyItemData, "ItemID", ""),
              "OtherLink",
              themeTitle,
              themeIndex          
            )
          }}
          href={node.getAttribute("href")}
          style={{ color: "#40a9ff", textDecoration: "underline" }}
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
    else{
      return (
        <a
          onClick={(event) => {
            event.preventDefault()
            handledLinkClicked(
              node.textContent,
              node.href,
              get(ltWeeklyItemData, "ItemID", ""),
              "External",
              a,
              themeIndex
            );
          }}
          style={{ color: "#40a9ff", textDecoration: "underline" }}
          href="javascript:void(0);"
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
  
  };
}
  const extractUrlFromDescription = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const anchor = doc.querySelector('a');
    return anchor ? anchor.href : '';
  };

  return (
    <>
      <Header
        {...props}
        ltWeeklyItemData={ltWeeklyItemData}
        onTabChange={onTabChange}
        activeTab={activeTab}
      />
      <main className="main">
        <div className="section__feed--detail">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {isLoader ? (
                  <>
                    <h1 style={{ marginTop: 200 }}> </h1>
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 30, color: "#000" }}
                          spin
                        />
                      }
                      className="loader__full"
                    />
                  </>
                ) : (
                  <div className="feed__main">
                    <div className="feed__left">
                      <div className="feed__left--wrapper sticky-top">
                        <div className="floating__nav--wrap">
                          <div className="floating__nav--title">
                            {moment(
                              ltWeeklyItemData.ItemName,
                              "MM DD YYYY"
                            ).format("MMMM D, YYYY")}{" "}
                            Weekly Themes
                          </div>
                          <div className="floating__nav--divider"></div>
                          <div className="">
                            <Scrollspy
                              className="floating__nav scrollspy"
                              items={arr}
                              currentClassName="isCurrent"
                              offset={-100}
                            >
                              <li
                                onClick={() =>
                                  clicScrollTop(ltWeeklyItemData.ItemID)
                                }
                                className="floating__nav--item"
                              >
                                <Link
                                  onClick={() =>
                                    linkClicked(
                                      "Intro",
                                      `/LTWeekly/${moment(
                                        ltWeeklyItemData.ItemName,
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${
                                        ltWeeklyItemData.ItemID
                                      }`,
                                      get(ltWeeklyItemData, "ItemID", ""),
                                      "IntroLink"
                                    )
                                  }
                                  to={`/LTWeekly/${moment(
                                    ltWeeklyItemData.ItemName,
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${
                                    ltWeeklyItemData.ItemID
                                  }`}
                                  className={
                                    ltWeeklyItemId === ltWeeklyItemData.ItemID
                                      ? "floating__nav--link isCurrent"
                                      : "floating__nav--link"
                                  }
                                >
                                  Intro
                                </Link>
                              </li>
                              {!isEmpty(ltWeeklyItemData) &&
                                get(ltWeeklyItemData, "ChildList", []).length >
                                  0 &&
                                get(ltWeeklyItemData, "ChildList", []).map(
                                  (item, i) => {
                                    return (
                                      <li
                                        onClick={() => itemChange(item.ItemID)}
                                        key={i}
                                        style={{ "padding-left": "8px" }}
                                        className="floating__nav--child-item"
                                      >
                                        {get(ltWeeklyItemData, "ChildList", [])
                                          .length > 1 && (
                                          <span className="floating__nav--count">
                                            {i + 1 + "."}
                                          </span>
                                        )}
                                        <Link
                                          onClick={() =>
                                            linkClicked(
                                              get(item, "ShortDescription", ""),
                                              `/LTWeekly/${moment(
                                                ltWeeklyItemData.ItemName,
                                                "MM DD YYYY"
                                              ).format("MMMM-DD-YYYY")}/${
                                                item.ItemID
                                              }`,
                                              get(
                                                ltWeeklyItemData,
                                                "ItemID",
                                                ""
                                              ),
                                              getThemeTitle(
                                                get(
                                                  item,
                                                  "ShortDescription",
                                                  ""
                                                )
                                              ),
                                              get(item,"ItemName","")
                                            )
                                          }
                                          className={
                                            ltWeeklyItemId === item.ItemID
                                              ? "floating__nav--child-link isCurrent"
                                              : "floating__nav--child-link"
                                          }
                                          to={`/LTWeekly/${moment(
                                            ltWeeklyItemData.ItemName,
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${
                                            item.ItemID
                                          }`}
                                        >
                                          {getThemeTitle(
                                            get(item, "ShortDescription", "")
                                          )}
                                        </Link>
                                      </li>
                                    );
                                  }
                                )}
                              {get(ltWeeklyItemData, "Stock", "") !== "" &&
                                get(ltWeeklyItemData, "Stock", "") !== null && (
                                  <li
                                    onClick={() => itemChange("stock-check")}
                                    className="floating__nav--item"
                                  >
                                    <Link
                                      onClick={() =>
                                        linkClicked(
                                          "Stock Market Check",
                                          `/LTWeekly/${moment(
                                            ltWeeklyItemData.ItemName,
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            ltWeeklyItemData,
                                            "ItemID",
                                        ""
                                      )}/stock-check`,
                                          get(ltWeeklyItemData, "ItemID", "")
                                    )
                                  }
                                  to={`/LTWeekly/${moment(
                                    ltWeeklyItemData.ItemName,
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    ltWeeklyItemData,
                                        "ItemID",
                                    ""
                                  )}/stock-check`}
                                  className={
                                    ltWeeklyItemId === "stock-check"
                                      ? "floating__nav--link isCurrent"
                                      : "floating__nav--link"
                                  }
                                >
                                  Stock Market Check
                                </Link>
                              </li>
                                )}
                              {get(ltWeeklyItemData, "OtherNews", []).length >
                                0 && (
                                <li
                                  onClick={() => itemChange("sub-sector")}
                                  className="floating__nav--item"
                                >
                                  <Link
                                    onClick={() =>
                                      linkClicked(
                                        "Key News by Sub-Sector",
                                        `/LTWeekly/${moment(
                                          ltWeeklyItemData.ItemName,
                                          "MM DD YYYY"
                                        ).format("MMMM-DD-YYYY")}/${get(
                                          ltWeeklyItemData,
                                          "ItemID",
                                          ""
                                        )}/sub-sector`,
                                        get(ltWeeklyItemData, "ItemID", "")
                                      )
                                    }
                                    to={`/LTWeekly/${moment(
                                      ltWeeklyItemData.ItemName,
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      ltWeeklyItemData,
                                      "ItemID",
                                      ""
                                    )}/sub-sector`}
                                    className={
                                      ltWeeklyItemId === "sub-sector"
                                        ? "floating__nav--link isCurrent"
                                        : "floating__nav--link"
                                    }
                                  >
                                    Key News by Sub-Sector
                                  </Link>
                                </li>
                              )}
                            </Scrollspy>
                          </div>
                        </div>
                        <div className="download__pdf">
                          <a
                            onClick={(e) =>{
                              e.preventDefault()
                              handledClicked(
                                "Download PDF",
                                `${HOST_URL}/Dashboard/pdf?WeeklyDate=${ltWeeklyItemData.ItemName}`,
                                get(ltWeeklyItemData, "ItemID", "")
                              )
                            }}
                           // href="javascript:void(0)"
                           href="#"
                            // target="_blank"
                            rel="noreferrer"
                            className="btn btn__purple btn__download-pdf"
                          >
                            Download PDF
                          </a>
                        </div>

                        {/* <div className="download__app">
                          <div className="download__app--inner">
                            <h4 className="download__app--title">
                              Download the App
                            </h4>
                            <div className="download__app--app-icon">
                              <KindredLogo />
                            </div>
                            <div className="download__app--text">
                              Where influencers connect, share insights and fuel
                              innovation. Download The Kindred App (search
                              thekindredapp) from the{" "}
                              <a
                                onClick={() =>
                                  handledClicked(
                                    "IOS App store",
                                    "https://apps.apple.com/in/app/thekindredapp/id1193984926",
                                    get(ltWeeklyItemData, "ItemID", "")
                                  )
                                }
                                href="javascript:void(0)"
                              >
                                iOS
                              </a>{" "}
                              and{" "}
                              <a
                                onClick={() =>
                                  handledClicked(
                                    "Android Play Store",
                                    "https://play.google.com/store/apps/details?id=com.mediaslopes.thekindredapp.android",
                                    get(ltWeeklyItemData, "ItemID", "")
                                  )
                                }
                                href="javascript:void(0)"
                              >
                                Android
                              </a>{" "}
                              App Stores.
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  {/* 
                              `${HOST_URL}/api/utility/pdf?path=%2fsitecore%2fcontent%2fKindredApp3%2fLT+Weekly%2f${ltWeeklyItemData.ItemName}`,
                  */}
                    <div className="feed__right">
                      <div className="feed__action--header">
                        <a
                          onClick={(e) =>{
                            e.preventDefault()
                            handledClicked(
                              "Full Report",
                              `${HOST_URL}/Dashboard/pdf?WeeklyDate=${ltWeeklyItemData.ItemName}`,
                              get(ltWeeklyItemData, "ItemID", "")
                            )
                          }}
                          // href="javascript:void(0)"
                          href="#"
                          target="_blank"
                          rel="noreferrer"
                          className="feed__action--btn btn__full-report"
                        >
                          <PdfSmall /> Full Report
                        </a>
                        {/* <Link
                        to="/weekly-detail"
                        className="feed__action--btn btn__copy-url"
                      >
                        Copy Theme URL
                      </Link> */}
                      </div>

                      <article className="article__content">
                        <section
                          className="article__intro"
                          id={ltWeeklyItemData.ItemID}
                        >
                          <div>
                            <div className="article__featured">
                              <img
                                src={
                                  get(ltWeeklyItemData, "Photo", "")?.includes(
                                    IMAGE_BLOB_URL
                                  )
                                    ? get(
                                        ltWeeklyItemData,
                                        "Photo",
                                        ""
                                      )?.replaceAll(HOST_URL, "")
                                    : get(ltWeeklyItemData, "Photo", "")
                                }
                                alt=""
                              />
                            </div>

                            <div className="article__intro--editor">
                              <div className="article__editor">
                                <Interweave
                                  content={get(
                                    ltWeeklyItemData,
                                    "LongDescription",
                                    ""
                                  )
                                    .replaceAll(
                                      'href="-/',
                                      `href="${HOST_URL}/-/`
                                    )
                                    .replaceAll(
                                      'src="-/',
                                      `src="${HOST_URL}/-/`
                                    )
                                    .replaceAll(
                                      get(
                                        ltWeeklyItemData,
                                        "LongDescription",
                                        ""
                                      ).split("<ol>")[1],
                                      get(
                                        ltWeeklyItemData,
                                        "LongDescription",
                                        ""
                                      ).split("</ol>")[1]
                                    )
                                    .replaceAll(
                                      "<ol>",
                                      `<ol>${liTagValue}</ol>`
                                    )
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/33DFFEEB-68E8-4602-B9E7-EC0C5418F505?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Market Share Shifts In Video Streaming Heading Into Q3 Earnings</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/0826E31A-8542-4E61-928F-164E7A4368D5?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Reflecting On Q3 Stock &amp; Sub-Sector Moves&hellip;What Stood Out The Most?</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/895F2AE6-3B15-4396-81A9-19B405CA7CF9?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Has The &ldquo;Death Of Malls&rdquo; Been Exaggerated?  Some Signs Of Life Emerge</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/33DFFEEB-68E8-4602-B9E7-EC0C5418F505?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Market Share Shifts In Video Streaming Heading Into Q3 Earnings</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/94D1602F-8DA4-4D3D-AE05-0AF700CC69B0?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Shopper Marketing &amp; CTV Underpin The Trade Desk&rsquo;s Long Term Growth Trajectory</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/78A6C6E0-3798-4D23-A434-2421EA2D5557?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Global Venture Capital Funding Plummets&hellip; Seed Stage Has Held Up The Best</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/5CFC3753-993A-4799-8F06-30CC949BC56F?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Bracing For The 2022 Holiday Shopping Season</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/C6810849-FD98-4393-A7B5-EA50B95D8244?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">&ldquo;Watch&rdquo; Out For Google As It Enters Wearables</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/A240C828-0DB2-43B4-B8B9-6156D6DEC40D?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">A Weak Data Point For Global Mobile App Spending Headed Into H2</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-07-2022/29698295-D108-4587-AF2E-03BB61CD3983?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Grab Bag: Twitter-Musk Saga Continues/ESPN-DraftKings May Be Coming/Sony VR Plans</a>`,"")
                                  
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/C2A0A1AB-BF92-4B96-87EF-BD7E4AC7A164?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Earnings Scorecard: Week 1</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/C31F8BAB-D7EF-4C22-A6F0-F2389C381D10?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Netflix Sets Stage For A Comeback Story... With AVOD As The Main Protagonist</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/8080DF0B-1473-4BE1-AF05-03CC00C41D78?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Snap Is Still Feeling The Heat From &ldquo;Easy To Pull&rdquo; Digital Ad Dollars</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/1B09E508-17AB-41DE-B2A6-6FEB5AB19994?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Mixed Bag For the Incumbent Telcos But Fiber &amp; FWA BBand Remains In Their Sight</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/E1DAA4EC-06FC-4522-B074-2592528E89C6?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">The &ldquo;Majority&rdquo; Of Marketers Seek Contingency Plans For Ad Spend, Per Agencies</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/EA3DB72C-9943-4982-BCC8-3F4F9C902377?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Last-Mile Delivery Companies Buckle Down On Profitability As Macro Headwinds Swirl  </a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/October-21-2022/BA99F678-9CC4-48EC-AED5-1F95B97AFCAB?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Grab-Bag: Amazon-NFL Black Friday Game/Klarna Spotlight/US Luxury Spend Cut Back</a>`,"")

                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/5D785861-F25A-4DD2-8FA4-E084D1D6624B?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">The NFL Sunday Ticket Goes To...Google! Live Sports Shifts Further To Streaming</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/11B0E605-87B3-4A04-9747-E4C991C8F5B1?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">The Elusive Days of &ldquo;Peak&rdquo; Content Spend Appear Be Here&hellip;</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/F76DF2D2-42DA-4F71-AC93-528861BA45E5?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Tech Regulation Finds A New Target&hellip; An EU Investigation Of Meta&rsquo;s Marketplace Biz    </a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/4ED540CB-F14F-426F-BACD-7C30F29D2F2D?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">Another Sign Points To A Tough Start For Netflix&rsquo;s AVOD Tier </a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/2F9D2DAE-4791-4C9F-BF32-77340BE02BA2?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">A Good Data-Pt Regarding Consumer Spending&hellip;Nike FQ2 Surprises On The Upside</a>`,"")
                                    .replaceAll(`<a href=\"https://thematics.liontree.com/LTWeekly/December-23-2022/0102B028-CF80-40AF-B579-8C3113B30A45?e=$email$\" target=\"_blank\" rel=\"noopener noreferrer\">2022 Capital Markets Year-End Wrap (Thank Goodness That Is Over)  </a>`,"")
                                  }
                                  transform={(node, children) =>
                                    introLinkTransformText(node, children)
                                  }
                                />
                              </div>
                              <div className="authors__block">
                                <ul className="authors__list">
                                  <li>
                                    <div className="authors__card">
                                      <div className="authors__profile">
                                        <img src={LeslieMallon} alt="" />
                                      </div>
                                      <div className="authors__info">
                                        <h4 className="authors__name">
                                          {get(
                                            ltWeeklyItemData,
                                            "SignatureName",
                                            ""
                                          )}
                                        </h4>
                                        <h5 className="authors__designation">
                                          Head of LionTree Public Markets
                                        </h5>
                                        <p className="authors__email">
                                          EM:{" "}
                                          <a
                                            // onClick={() =>
                                            //   handledClicked(
                                            //     `Mail to ${get(
                                            //       ltWeeklyItemData,
                                            //       "SignatureEmail",
                                            //       ""
                                            //     )}`,
                                            //     `mailto:${get(
                                            //       ltWeeklyItemData,
                                            //       "SignatureEmail",
                                            //       ""
                                            //     )}`,
                                            //     get(
                                            //       ltWeeklyItemData,
                                            //       "ItemId",
                                            //       ""
                                            //     )
                                            //   )
                                            // }
                                            href={`mailto:${get(
                                              ltWeeklyItemData,
                                              "SignatureEmail",
                                              ""
                                            )}`}
                                          >
                                            {" "}
                                            {get(
                                              ltWeeklyItemData,
                                              "SignatureEmail",
                                              ""
                                            )}{" "}
                                          </a>
                                        </p>
                                        <p className="authors__phone">
                                          PH:
                                          <a
                                            // onClick={() =>
                                            //   handledClicked(
                                            //     `Telphone to ${get(
                                            //       ltWeeklyItemData,
                                            //       "SignaturePhoneNo",
                                            //       ""
                                            //     )}`,
                                            //     `tel:${get(
                                            //       ltWeeklyItemData,
                                            //       "SignaturePhoneNo",
                                            //       ""
                                            //     )}`,
                                            //     get(
                                            //       ltWeeklyItemData,
                                            //       "ItemId",
                                            //       ""
                                            //     )
                                            //   )
                                            // }
                                            href={`tel:${get(
                                              ltWeeklyItemData,
                                              "SignaturePhoneNo",
                                              ""
                                            )}`}
                                          >
                                            {"  "}
                                            {get(
                                              ltWeeklyItemData,
                                              "SignaturePhoneNo",
                                              ""
                                            )}{" "}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </section>
                        {!isEmpty(ltWeeklyItemData) &&
                          get(ltWeeklyItemData, "ChildList", []).length > 0 &&
                          get(ltWeeklyItemData, "ChildList", []).map(
                            (item, i) => {
                              return (
                                <div id={item.ItemID}>
                                  <div key={i} className="article__header">
                                    <div className="active">
                                      <div
                                        style={{ "padding-left": "8px" }}
                                        className={
                                          ltWeeklyItemId === item.ItemID
                                            ? "article__title isCurrent"
                                            : "article__title"
                                        }
                                      >
                                        {get(ltWeeklyItemData, "ChildList", [])
                                          .length > 1 ? (
                                          <>
                                            {i +
                                              1 +
                                              ". " +
                                              getThemeTitle(
                                                item.ShortDescription
                                              )}
                                          </>
                                        ) : (
                                          getThemeTitle(item.ShortDescription)
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="article__editor">
                                    <Interweave
                                      content={get(item, "LongDescription", "")
                                        .replaceAll(
                                          'href="-/',
                                          `href="${HOST_URL}/-/`
                                        )
                                        .replaceAll("<a", `<a target='_blank'`)
                                        .replaceAll(
                                          'src="-/',
                                          `src="${HOST_URL}/-/`
                                        )}
                                      transform={(node, children) =>
                                        transformText(
                                          get(item, "ShortDescription", ""),
                                          node,
                                          children
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        {get(ltWeeklyItemData, "Stock", "") !== "" && get(ltWeeklyItemData, "Stock", "") !== null
                        &&
                        <div id="stock-check">
                          <div className="article__header">
                            <div
                              className={
                                ltWeeklyItemId === "stock-check"
                                  ? "article__title isCurrent"
                                  : "article__title"
                              }
                            >
                              Stock Market Check
                            </div>
                          </div>
                          <div className="article__editor">
                            <div className="text-center">
                              <img

                                  src=
                                  {
                                    get(ltWeeklyItemData, "Stock", "")?.includes(
                                      IMAGE_BLOB_URL
                                    )
                                      ? get(
                                          ltWeeklyItemData,
                                          "Stock",
                                          ""
                                        )?.replaceAll(HOST_URL, "")
                                      : get(ltWeeklyItemData, "Stock", "")
                                  }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        }
                        <div id="sub-sector">
                          {get(ltWeeklyItemData, "OtherNews", []).length >
                            0 && (
                            <div className="article__header" id="sub-sector">
                              <div
                                className={
                                  ltWeeklyItemId === "sub-sector"
                                    ? "article__title isCurrent"
                                    : "article__title"
                                }
                              >
                                Key News by Sub-Sector
                              </div>
                            </div>
                          )}
                          <div className="article__editor">
                            {otherNews.length > 0 &&
                              otherNews.map((news, i) => {
                                return (
                                  <>
                                    <div className="article__editor--other-news">
                                      <h4 className="">{news.catName} </h4>
                                      <div id={i} className="test">
                                        <ul>
                                          {get(news, "items", []).map(
                                            (item, j) => {

                                              let a = get(
                                                item,
                                                "LongDescription",
                                                ""
                                              ).replaceAll("<p>","").replaceAll("</p>","")
                                                .replaceAll(
                                                  "<a",
                                                  `<a target='_blank'`
                                                )
                                                .replaceAll(
                                                  'href="-/',
                                                  `href="${HOST_URL}/-/`
                                                )
                                                .replaceAll(
                                                  'src="-/',
                                                  `src="${HOST_URL}/-/`
                                                );
                                              let b = a.slice(
                                                0,
                                                a.indexOf(". ")
                                              );
                                              let contentString;
                                              if(!get(
                                                item,
                                                "LongDescription",
                                                ""
                                              ).includes("<strong>")){
                                              let c = a.slice(
                                                a.indexOf(". ") + 1,
                                                a.length
                                              );
                                              if(b.includes("Microsoft is holding a “special event” in New York City later this week where it’s expected to debut three")){
                                                contentString =
                                                "<strong>" +
                                                b +
                                                "). " +
                                                "</strong>"
                                              }else{
                                              contentString =
                                                "<strong>" +
                                                b +
                                                ". " +
                                                "</strong>" +
                                                c ;
                                              }
                                              }else{
                                                contentString = a
                                              }
                                              return (
                                                <li
                                                  key={j}
                                                  className="article__editor"
                                                  style={{marginBottom: "15px"}}
                                                >
                                                  <Interweave
                                                    content={contentString}
                                                    transform={(
                                                      node,
                                                      children
                                                    ) =>
                                                      transformText(
                                                        "Key News by Sub-Sector",
                                                        node,
                                                        children,
                                                        b,
                                                        a
                                                      )
                                                    }
                                                  />
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </article>

                      <div className="feed__contant--nav-mobile">
                        <button
                          onClick={() => {
                            handledClicked(
                              "Disclaimers",
                              `${process.env.REACT_APP_SITE_URL}/LTWeekly/disclaimers`,
                              get(ltWeeklyItemData, "ItemID", "")
                            );
                          }}
                          className="btn feed__contant--nav--btn"
                        >
                          Disclaimers
                        </button>
                      </div>
                      <div className="feed__contant--nav">
                        <button
                          disabled={isNextDisable}
                          onClick={() => {
                            handleLinkClick(
                              "Next LT Weekly",
                              ltWeeklyItemData.ItemID
                            );
                            getLtWeekly("next");
                          }}
                          className="btn feed__contant--nav--btn"
                        >
                          <LeftArrow className="feed__contant--nav-icon left-icon" />
                          Next LT Weekly
                        </button>
                        <button
                          onClick={() => {
                            handledClicked(
                              "Disclaimers",
                              `${process.env.REACT_APP_SITE_URL}/LTWeekly/disclaimers`,
                              get(ltWeeklyItemData, "ItemID", "")
                            );
                          }}
                          className="btn feed__contant--nav--btn"
                        >
                          Disclaimers
                        </button>
                        <button
                          disabled={isPrevDisable}
                          onClick={() => {
                            handleLinkClick(
                              "Previous LT Weekly",
                              ltWeeklyItemData.ItemID
                            );
                            getLtWeekly("prev");
                          }}
                          className="btn feed__contant--nav--btn"
                        >
                          Previous LT Weekly
                          <RightArrow className="feed__contant--nav-icon right-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className="nav__footer">
        <div className="nav__footer--item">
          <button
            disabled={isNextDisable}
            onClick={() => {
              handleLinkClick("Next LT Weekly", ltWeeklyItemData.ItemID);
              getLtWeekly("next");
            }}
            className="btn nav__footer--btn"
          >
            <LeftArrow className="nav__footer--icon" />
            NEXT LT WEEKLY
          </button>
        </div>
        <div className="nav__footer--item">
          <button className="btn nav__footer--btn" onClick={showDrawer}>
            <Filter className="nav__footer--icon" />
            This Week's Themes
          </button>
        </div>
        <div className="nav__footer--item">
          <button
            disabled={isPrevDisable}
            onClick={() => {
              handleLinkClick("Previous LT Weekly", ltWeeklyItemData.ItemID);
              getLtWeekly("prev");
            }}
            className="btn nav__footer--btn"
          >
            <RightArrow className="nav__footer--icon" />
            PREVIOUS LT WEEKLY
          </button>
        </div>
      </div>

      <div className="scroll__top">
        <button
          onClick={() => {
            handleLinkClick("Scroll Top", ltWeeklyItemData.ItemID);
            clicScrollTop(ltWeeklyItemData.ItemID);
          }}
          type="button"
          className="btn scroll__top--btn"
        >
          <ScrollTop />
        </button>
      </div>
      <Drawer
        className="drawer__default"
        closable="false"
        placement="left"
        onClose={onClose}
        visible={visible}
      >
        <div className="drawer__default--header">
          <button
            onClick={() => onClose()}
            className="btn drawer__default--header--close"
          >
            <Close />
          </button>
        </div>
        <div className="dwawer__default--header">
          <div className="floating__nav--wrap">
            <div className="floating__nav--title">
              {moment(ltWeeklyItemData.ItemName, "MM DD YYYY").format(
                "MMMM D, YYYY"
              )}{" "}
              Weekly Themes
            </div>
            <div className="floating__nav--divider"></div>
            <div className="floating__nav--mobile">
              <Scrollspy
                className="floating__nav scrollspy"
                items={arr}
                currentClassName="isCurrent"
                offset={-100}
              >
                <li
                  onClick={() => mobileItemChange(ltWeeklyItemData.ItemID)}
                  className="floating__nav--item"
                >
                  <Link
                    onClick={() =>
                      linkClicked(
                        "Intro",
                        `/LTWeekly/${moment(
                          ltWeeklyItemData.ItemName,
                          "MM DD YYYY"
                        ).format("MMMM-DD-YYYY")}/${ltWeeklyItemData.ItemID}`,
                        get(ltWeeklyItemData, "ItemID", ""),
                        "IntroLink"
                      )
                    }
                    to={`/LTWeekly/${moment(
                      ltWeeklyItemData.ItemName,
                      "MM DD YYYY"
                    ).format("MMMM-DD-YYYY")}/${ltWeeklyItemData.ItemID}`}
                    className={
                      ltWeeklyItemId === ltWeeklyItemData.ItemID
                        ? "floating__nav--link isCurrent"
                        : "floating__nav--link"
                    }
                  >
                    Intro
                  </Link>
                </li>
                {!isEmpty(ltWeeklyItemData) &&
                  get(ltWeeklyItemData, "ChildList", []).length > 0 &&
                  get(ltWeeklyItemData, "ChildList", []).map((item, i) => {
                    return (
                      <li
                        onClick={() => mobileItemChange(item.ItemID)}
                        key={i}
                        style={{ "padding-left": "8px" }}
                        className="floating__nav--item"
                      >
                        {get(ltWeeklyItemData, "ChildList", []).length > 1 && (
                          <span className="floating__nav--count">
                            {i + 1 + "."}
                          </span>
                        )}
                        <Link
                          className={
                            ltWeeklyItemId === item.ItemID
                              ? "floating__nav--link isCurrent"
                              : "floating__nav--link"
                          }
                          onClick={() =>
                            linkClicked(
                              get(item, "ShortDescription", ""),
                              `/LTWeekly/${moment(
                                ltWeeklyItemData.ItemName,
                                "MM DD YYYY"
                              ).format("MMMM-DD-YYYY")}/${item.ItemID}`,
                              get(ltWeeklyItemData, "ItemID", ""),
                              getThemeTitle(get(item, "ShortDescription", "")),
                              get(item,"ItemName","")
                            )
                          }
                          to={`/LTWeekly/${moment(
                            ltWeeklyItemData.ItemName,
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${item.ItemID}`}
                        >
                          {getThemeTitle(item.ShortDescription)}
                        </Link>
                      </li>
                    );
                  })}
                {get(ltWeeklyItemData, "Stock", "") !== "" && get(ltWeeklyItemData, "Stock", "") !== null 
                &&
                <li
                  onClick={() => mobileItemChange("stock-check")}
                  className="floating__nav--item"
                >
                  <Link
                    onClick={() =>
                      linkClicked(
                        "Stock Market Check",
                          `/LTWeekly/${moment(
                            ltWeeklyItemData.ItemName,
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${get(
                            ltWeeklyItemData,
                              "ItemID",
                            ""
                          )}/stock-check`,
                        get(ltWeeklyItemData, "ItemId", "")
                      )
                    }
                    to={`/LTWeekly/${moment(
                      ltWeeklyItemData.ItemName,
                      "MM DD YYYY"
                    ).format("MMMM-DD-YYYY")}/${get(
                      ltWeeklyItemData,
                          "ItemID",
                      ""
                    )}/stock-check`}
                    className={
                      ltWeeklyItemId === "stock-check"
                        ? "floating__nav--link isCurrent"
                        : "floating__nav--link"
                    }
                  >
                    Stock Market Check
                  </Link>
                </li>
                }
                {get(ltWeeklyItemData, "OtherNews", []).length > 0 && (
                  <li
                    onClick={() => mobileItemChange("sub-sector")}
                    className="floating__nav--item"
                  >
                    <Link
                      onClick={() =>
                        linkClicked(
                          "Key News by Sub-Sector",
                          `/LTWeekly/${moment(
                            ltWeeklyItemData.ItemName,
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${get(
                            ltWeeklyItemData,
                            "ItemID",
                            ""
                          )}/sub-sector`,
                          get(ltWeeklyItemData, "ItemID", "")
                        )
                      }
                      to={`/LTWeekly/${moment(
                        ltWeeklyItemData.ItemName,
                        "MM DD YYYY"
                      ).format("MMMM-DD-YYYY")}/${get(
                        ltWeeklyItemData,
                        "ItemID",
                        ""
                      )}/sub-sector`}
                      className={
                        ltWeeklyItemId === "sub-sector"
                          ? "floating__nav--link isCurrent"
                          : "floating__nav--link"
                      }
                    >
                      Key News by Sub-Sector
                    </Link>
                  </li>
                )}
              </Scrollspy>
            </div>
          </div>

          <div className="download__app download__app--dark">
            <div className="download__app--inner">
              <h4 className="download__app--title">Download the App</h4>
              <div className="download__app--app-icon">
                <KindredLogo />
              </div>
              <div className="download__app--text">
                Where influencers connect, share insights and fuel innovation.
                Download The Kindred App (search thekindredapp) from the{" "}
                <a
                  onClick={() =>
                    handledClicked(
                      "IOS App store",
                      "https://apps.apple.com/in/app/thekindredapp/id1193984926",
                      get(ltWeeklyItemData, "ItemID", "")
                    )
                  }
                  href="javascript:void(0)"
                >
                  iOS
                </a>{" "}
                and{" "}
                <a
                  onClick={() =>
                    handledClicked(
                      "Android Play Store",
                      "https://play.google.com/store/apps/details?id=com.mediaslopes.thekindredapp.android",
                      get(ltWeeklyItemData, "ItemID", "")
                    )
                  }
                  href="javascript:void(0)"
                >
                  Android
                </a>{" "}
                App Stores.
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Footer
        isCookieConsent={isCookieConsent}
        setIsCookieConsent={setIsCookieConsent}
        {...props}
        ltWeeklyItemData={ltWeeklyItemData}
      />
      <div className={!isCookieConsent ? "overlay-restrict" : ""}></div>
    </>
  );
};

export default WeeklyDetailComponent;
