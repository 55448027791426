import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FooterComponent from "../../components/common/Footer/component"
import Header from "../../components/common/Header/component"

import "./styles.scss"
const REDIRECT_URL = "/iimform";
export default function Disclaimers(props) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const [isCookieConsent, setIsCookieConsent] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const onTabChange = (value) => {
    if (value === "home") {
      setActiveTab(value);
      navigate("/LTWeekly", { state: {} });
    } else if (value === "archives") {
      setActiveTab(value);
      navigate("/LTWeekly/archives/2024", { state: {} });
    } else if (value === "about") {
      setActiveTab(value);
      navigate("/LTWeekly/about", { state: {} });
    }
  };

  useEffect(() => {
    const { pathname } = location;
    // let userIsExists = localStorage.getItem("email");
    // if (!userIsExists) {
    //   window.location.href = REDIRECT_URL;
    // }
    if (pathname === "/LTWeekly") {
      setActiveTab("home");
      navigate("/LTWeekly", { state: {} });
    } else if (pathname.includes("/LTWeekly/archives/")) {
      setActiveTab("archives");
      navigate(pathname, { state: {} });
    } else if (pathname === "/LTWeekly/about") {
      setActiveTab("about");
      navigate("/LTWeekly/about", { state: {} });
    }
  }, [activeTab, navigate]);

  return (
    <>
      <Header
        {...props}
        onTabChange={onTabChange}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        SavedLTWebsiteAnalytics={props.SavedLTWebsiteAnalytics}
      />
      <main className="main">
        <div className="disclaimers-section">
          <div className="disclaimers-section-wrapper">
            <div className="disclaimers-section-text">
              <p>
                This material is provided by LionTree LLC solely for
                informational purposes and is provided as of the date indicated
                above. LionTree is not providing or undertaking to provide any
                financial, economic, legal, accounting, tax or other advice or
                recommendation in or by virtue of this material. The
                information, statements, comments, views, and opinions provided
                in this material are general in nature and (i) are not intended
                to be and should not be construed as the provision of investment
                advice by LionTree, (ii) do not constitute and should not be
                construed as an offer to buy or sell any securities or to make
                or consider any investment or course of action, and (iii) may
                not be current. LionTree does not make any representation or
                warranty as to the accuracy or completeness of any of the
                information, statements, comments, views or opinions contained
                in this material, and any liability therefor (including in
                respect of direct, indirect or consequential loss or damage of
                any kind whatsoever) is expressly disclaimed. LionTree does not
                undertake any obligation whatsoever to provide any form of
                update, amendment, change or correction to any of the
                information, statements, comments, views, or opinions set forth
                in this material.
              </p>
              <p>
                No part of this material may, without LionTree LLC's prior
                written consent, be reproduced, redistributed, published, copied
                or duplicated in any form, by any means.
              </p>
              <p>
                Certain securities products and services are provided by
                LionTree through LionTree Advisors LLC, a wholly owned
                subsidiary of LionTree LLC which is a registered broker-dealer
                and a member of FINRA and SIPC. LionTree Advisors LLC is not
                providing this material and is not responsible for it or any of
                the content herein.
              </p>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent isCookieConsent={isCookieConsent}
     setIsCookieConsent={setIsCookieConsent} {...props} />
     <div className={!isCookieConsent ? "overlay-restrict": ""}></div>

    </>
  )
}
