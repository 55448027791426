import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics, initPhase, getResources, initHomePhase } from '../../store/home/duck'

import HomeComponent from './component'

const HomeContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    getResources, 
    initHomePhase,
    initPhase
  }
)(HomeComponent)

export default HomeContainer
