import React from "react"
import { LogoThree } from "../../components/icons"

import "./styles.scss"
const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export default function SubscriptionCenter(props) {
  return (
    <>
      <div className="subscription-section">
        <div className="subscription-section-wrapper">
          <div className="subscription-section-logo">
            <LogoThree />
          </div>
          <div className="subscription-section-text">
            Hello! It seems like you are not subscribed to view that content. If
            you are, please click on the links in the email you have received. You
            can request access on the next screen.
          </div>
          <div className="subscription-section-actions">
            <button onClick={()=>window.open(REDIRECT_URL)} className="btn btn-lg btn__white">
              Proceed to the Subscription Center
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
