import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header/container";
import Footer from "../../components/common/Footer/container";
import Announcement from "../../components/common/Announcement/container";
import Feed from "../Feed/container";
import Archives from "../Archives/container";
import About from "../About/container";

import "./styles.scss";
const REDIRECT_URL = "/iimform";

export default function HomeComponent(props) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();

  const onTabChange = (value) => {
    if (value === "home") {
      setActiveTab(value);
      navigate("/LTWeekly", { state: {} });
    } else if (value === "archives") {
      setActiveTab(value);
      navigate("/LTWeekly/archives/2024", { state: {} });
    } else if (value === "about") {
      setActiveTab(value);
      navigate("/LTWeekly/about", { state: {} });
    }
  };

  const [isCookieConsent, setIsCookieConsent] = useState(false);
  useEffect(() => {
    const { pathname } = location;
    let userIsExists = localStorage.getItem("email");
    if (!userIsExists) {
      // window.location.href = REDIRECT_URL;
    }
    if (pathname === "/LTWeekly") {
      setActiveTab("home");
      navigate("/LTWeekly", { state: {} });
    } else if (pathname.includes("/LTWeekly/archives/")) {
      setActiveTab("archives");
      navigate(pathname, { state: {} });
    } else if (pathname === "/LTWeekly/about") {
      setActiveTab("about");
      navigate("/LTWeekly/about", { state: {} });
    }
  }, [activeTab, navigate]);

  return (
    <>
      <Header
        {...props}
        onTabChange={onTabChange}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        SavedLTWebsiteAnalytics={props.SavedLTWebsiteAnalytics}
      />

      <main className="main">
        {activeTab === "home" && (
          <>
            <Announcement {...props} />
            <Feed {...props} />
          </>
        )}
        {activeTab === "archives" && (
          <>
            <Archives
              {...props}
              onTabChange={onTabChange}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </>
        )}
        {activeTab === "about" && (
          <>
            <About {...props} />
          </>
        )}
      </main>

      <Footer isCookieConsent={isCookieConsent}
     setIsCookieConsent={setIsCookieConsent}
     {...props} />
    
     <div className={!isCookieConsent ? "overlay-restrict": ""}></div>
    </>
  );
}
