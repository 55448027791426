import * as React from "react"

const Close = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.532 14.532 0 16 1.468 9.468 8 16 14.532 14.532 16 8 9.468 1.468 16 0 14.532 6.532 8 0 1.468 1.468 0 8 6.532Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default Close

