import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { get, isEmpty } from "lodash";
import { Logo, Hamburger, Close } from "../../icons";
import moment from "moment";
// import ReactModal from "react-modal";
import "./styles.scss";
import { useLocation } from "react-router-dom";
// import { useCookies } from "react-cookie";

const Header = ({
  props,
  ltWeeklyItemData,
  onTabChange,
  activeTab,
  SavedLTWebsiteAnalytics,
}) => {
  const [visible, setVisible] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [cookies, setCookie] = useCookies(["isModalOpen"]);
  const location = useLocation();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const headerAnalytics = (actionDescription) => {
  //   let url = "";
  //   if (
  //     actionDescription === "Home Page" ||
  //     actionDescription === "Home Logo"
  //   ) {
  //     url = "/LTWeekly";
  //   } else if (actionDescription === "About Page") {
  //     url = "/LTWeekly/about";
  //   } else {
  //     url = "/LTWeekly/archives/2022";
  //   }
  //   let email = localStorage.getItem("email")
  //   let format = 'MMMM DD, YYYY';
  //   let currentUtcTime = new Date()
  //   // Converts the UTC time to a locale specific format, including adjusting for timezone.
  //   let currentDateTimeCentralTimeZone = new Date(currentUtcTime.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  //   let dayNight = currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
  //   let currentDate = moment(new Date, format).format(format);
  //   let currentHr = currentDateTimeCentralTimeZone.getHours();
  //   let currentMin = currentDateTimeCentralTimeZone.getMinutes();
  //   currentDate = currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
  //   let obj = {
  //     Date: currentDate,
  //     Url: window.location.host + url,
  //     ActionType: "pageview",
  //     NewsletterID: "",
  //     NewsletterDate: "",
  //     ThemeID: "",
  //     ActionDescription: actionDescription,
  //   }
  //   SavedLTWebsiteAnalytics(obj)
  // }

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const handledClicked = (actionDescription, val) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    // "June 2, 2022 at 1:30pm EST"
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: get(ltWeeklyItemData, "ItemId", ""),
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
    };
    SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  // const handleOk = () => {
  //   setCookie("isModalOpen", "opened", { path: "/" });
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   if (isEmpty(cookies)) {
  //     setIsModalOpen(true);
  //   }
  // }, [isModalOpen]);

  useEffect(() => {
    let { pathname } = location;
    if (pathname === "/LTWeekly") {
      // headerAnalytics('Home Page')
    } else if (pathname.includes("/LTWeekly/archives/")) {
      // headerAnalytics('Archive  Page')
    } else if (pathname === "/LTWeekly/about") {
      // headerAnalytics('About')
    }
  }, [location.pathname]);

  return (
    <>
      <header className="top__navigation top__navigation--fixed">
        <div className="top__navigation--container">
          <div className="top__navigation--row">
            <div className="top__navigation--left">
              <div className="top__navigation--hamburger">
                <button className="btn btn__hamburger" onClick={showDrawer}>
                  <Hamburger />
                </button>
              </div>
              <div className="top__navigation--logo">
                <Link
                  to="/LTWeekly"
                  // onClick={() => headerAnalytics("Home Logo")}
                  className="brand"
                >
                  <Logo />
                </Link>
              </div>
              <ul className="top__navigation--nav">
                <li>
                  <button
                    className={
                      activeTab === "home" ? "nav__link active" : "nav__link"
                    }
                    onClick={() => {
                      onTabChange("home");
                      // headerAnalytics("Home Page")
                    }}
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "archives"
                        ? "nav__link active"
                        : "nav__link"
                    }
                    onClick={() => {
                      // headerAnalytics("Archive Page")
                      onTabChange("archives");
                    }}
                  >
                    Archives
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "about" ? "nav__link active" : "nav__link"
                    }
                    onClick={() => {
                      // headerAnalytics("About Page")
                      onTabChange("about");
                    }}
                  >
                    About
                  </button>
                </li>
              </ul>
            </div>
            <div className="top__navigation--right">
              <a
                onClick={() =>
                  handledClicked(
                    "Request Subscription",
                    "https://www.liontree.com/ltweekly?inboundpath=themeweb"
                  )
                }
                href="javascript:void(0)"
                // target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn__purple btn__subscribe">
                  Request Subscription
                </button>
              </a>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        className="drawer__nav--drawer"
        placement="left"
        onClose={onClose}
        visible={visible}
      >
        <div className="drawer__nav--header">
          <button onClick={onClose} className="btn drawer__nav--close">
            <Close />
          </button>
        </div>
        <div className="drawer__nav--container">
          <div className="drawer__nav--wrap">
            <ul className="drawer__nav">
              <li>
                <button
                  className={
                    activeTab === "home" ? "nav__link active" : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    onTabChange("home");
                    // headerAnalytics("Home Page")
                  }}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  className={
                    activeTab === "archives" ? "nav__link active" : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    onTabChange("archives");
                    // headerAnalytics("Archives Page")
                  }}
                >
                  Archives
                </button>
              </li>
              <li>
                <button
                  className={
                    activeTab === "about" ? "nav__link active" : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    onTabChange("about");
                    // headerAnalytics("About Page")
                  }}
                >
                  About
                </button>
              </li>
            </ul>
          </div>
          <div className="drawer__nav--footer">
            <a
              onClick={() =>
                handledClicked(
                  "Request Subscription",
                  "https://www.liontree.com/ltweekly?inboundpath=themeweb"
                )
              }
              href="javascript:void(0)"
              // target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-sm btn__white w-100">
                Request Subscription
              </button>
            </a>
          </div>
        </div>
      </Drawer>
      {/*<ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="cookies"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-body">
            <div className="cookies-content">
              <div className="cookies-content-text">
                This site uses cookies to track user preferences and to improve user
                experience.
                <strong>
                  {" "}
                  If you do not want us to use cookies, please disable cookies in your
                  web browser.
                </strong>{" "}
                Note that if you <strong>disable cookies,</strong> some website
                functionality will not work for you. For more information about
                cookies and how we handle your personal information, please click on
                our {" "}
                <a
                  onClick={() =>
                    handledClicked(
                      "Privacy Policy",
                      `${process.env.REACT_APP_API_HOSTNAME}/-/media/Privacy/LionTree-PrivacyPolicy.ashx`  
                    )
                  }
                  href="javascript:void(0)"
                >
                  Privacy Policy
                </a>.
              </div>
              <button className="btn btn__purple btn__cookies" onClick={() => handleOk()}>Ok</button>
          </div>
          </div>
        </div>
      </ReactModal>*/}
    </>
  );
};
export default Header;
