import * as React from "react"

const PdfSmall = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.669 0 .003 5.375v2.268h1.144V6.161h5.19V2.322H5.193v2.695H1.829l3.363-3.87h9.458v6.496h1.146V0H4.669Z"
        fill="#4D1B6C"
      />
      <path
        d="M1.248 9.544h1.186c.498 0 1.034.246 1.012.862-.019.554-.352.89-1.012.89H1.247V9.543ZM0 8.512v5.96h1.248v-2.164H2.63c1.122 0 1.911-.793 1.911-1.865 0-1.123-.849-1.93-1.91-1.93H0ZM6.667 9.544h.923c1.21 0 1.658.786 1.662 1.885.001 1.12-.499 2.028-1.553 2.028H6.667V9.544ZM5.454 8.512v5.96H7.95c1.709 0 2.602-1.504 2.575-3.043-.03-1.676-1.179-2.917-2.764-2.917H5.454ZM11.555 8.512v5.96h1.248v-2.513h2.62v-1.051h-2.62V9.553H15.8v-1.04h-4.245Z"
        fill="#4D1B6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.65 13.326v6.882l-2.564-.003-1.053 1.146 4.763.003v-8.028H14.65ZM.003 15.347v5.993l4.764.004-1.055-1.146-2.565-.002v-4.849H.003Z"
        fill="#4D1B6C"
      />
      <path
        d="M7.331 15.977v5L5.3 18.945l-.81.808 3.414 3.42 3.415-3.42-.81-.808-2.031 2.03v-4.998H7.33Z"
        fill="#4D1B6C"
      />
    </svg>
  )
}
export default PdfSmall

